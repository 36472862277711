import { useState, useRef, useEffect } from "react";
import { Table, Row, Col, Button, Modal, Form, InputGroup, Dropdown, DropdownButton, Toast, ToastContainer } from "react-bootstrap";
import api from '../api/getInvestmentsAPI';
import postAPI from '../api/postInvestmentAPI';
import deleteAPI from '../api/deleteInvestmentAPI';


const InvestmentsPage = ({sessionToken}) => {

const [investorsList, setInvestorsList] = useState([]);
const dataLoaded = useRef(false);

useEffect(() => {
    fetchInvestorList();
}, [sessionToken]);


const [showToast, setToastShow] = useState(false);
const [showDeleteToast, setDeleteToastShow] = useState(false);


const [showDeleteInvestmentModal, setDeleteInvestmentModal] = useState(false);
const handleDeleteModalClose = () => setDeleteInvestmentModal(false);
const handleDeleteModalShow = () => setDeleteInvestmentModal(true);
const [itemToBeDeleted, setItemToBeDeleted] = useState();


const [showAddInvestmentModal, setAddInvestmentModal] = useState(false);
const handleAddModalClose = () => setAddInvestmentModal(false);
const handleAddModalShow = () => setAddInvestmentModal(true);

const [investorName, setInvestorName] = useState("");
const [investmentAmount, setInvestmentAmount] = useState("");
const [investmentStart, setInvestmentStart] = useState("");
const [investmentEnd, setInvestmentEnd] = useState("");
const [interest, setInterest] = useState("");
const [investmentType, setInvestmentType] = useState("Select type");

const [addInvestmentValidationFailed, setaddInvestmentValidationFailed] = useState(false);


const fetchInvestorList = async ()=> {
    const fetchList = async () => {        
        const config = {
            headers: {
              Authorization: 'Bearer ' + sessionToken}
        };
        const response = await api.get('', config);
        setInvestorsList(response.data);
    }
    if (dataLoaded.current === false) {
        fetchList();
    }
    return ()=> { dataLoaded.current = true; }
}

const handleInvestmentAdd = ()=> {
    if((checkValidation())) {
        setaddInvestmentValidationFailed(false);
        console.log(investorName,investmentAmount,investmentStart,investmentEnd,interest,investmentType);
        postInvestment();
        handleAddModalClose();
        resetValues();
        setToastShow(true);
    }
};

const handleInvestmentDelete = () => {
    console.log("deleting investment: " + itemToBeDeleted);
    deleteInvestment();
}

const checkValidation = () => {
    if(investorName.length == 0) {
        console.log("Investorname: "+ investorName.length);
        setaddInvestmentValidationFailed(true);
        return false;
    } 

    if(investmentAmount == 0) {
        console.log("amount: "+ investorName.length);
        setaddInvestmentValidationFailed(true);
        return false;
    } 
    
    if(investmentStart == 0) {
        setaddInvestmentValidationFailed(true);
        return false;
    } 
    
    if(investmentEnd == 0) {
        setaddInvestmentValidationFailed(true);
        return false;
    } 

    if(interest==0) {
        setaddInvestmentValidationFailed(true);
        return false;
    } 

    if(investmentType==0) {
        setaddInvestmentValidationFailed(true);
        return false;
    } 

    if(investmentType=="Select type") {
        setaddInvestmentValidationFailed(true);
        return false;
    }

    
    setaddInvestmentValidationFailed(true);
    return true;
    

}

const resetValues = () => {
    setInvestorName("");
    setInvestmentAmount("");
    setInvestmentStart("");
    setInvestmentEnd("");
    setInterest("");
    setInvestmentType("Select type");
}

const dateFormat = (date) => {
    let dateObject = new Date(date);
    return (dateObject.getDate() + "." + (dateObject.getMonth()+1) + "." + dateObject.getFullYear());
}

const postInvestment = async () => {
    
    const header = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + sessionToken,
        }
    }

    const payload = {
            investorName: investorName,
            investmentAmount: investmentAmount,
            investmentStart: investmentStart,
            investmentEnd: investmentEnd,
            interest: interest,
            investmentType: investmentType
        };

        const result = await postAPI.post('', payload, header);

        if(result.data.result==="FAIL") {
            return false;
        } else {
            fetchInvestorList();
        }
}

const deleteInvestment = async () => {
    const payload = {
        investmentId: itemToBeDeleted,
    };

    const header = {
            headers: {
                "Content-Type": "application/json", 
                "Authorization": "Bearer "+ sessionToken,       
        } 
    }

    const result = await deleteAPI.post('https://modena.monte360.com/investor/deleteInvestment.php', payload, header);

    if(result.data.result==="FAIL") {
        console.log("Delte result: " + result.data.result);
        return false;
    } else {
        setItemToBeDeleted("");
        fetchInvestorList();
        handleDeleteModalClose();
        setDeleteToastShow(true);
    }
}


    return (
        <>       
        <ToastContainer className="p-3" position='bottom-end' style={{ zIndex: 1 }}>
        <Toast onClose={() => setToastShow(false)} show={showToast} delay={3000} bg='success' autohide>
                <Toast.Body className='text-white start'>New Investment was added</Toast.Body>
            </Toast>
         </ToastContainer>
        
         <ToastContainer className="p-3" position='bottom-end' style={{ zIndex: 1 }}>
        <Toast onClose={() => setDeleteToastShow(false)} show={showDeleteToast} delay={3000} bg='danger' autohide>
                <Toast.Body className='text-white start'>Investment was deleted</Toast.Body>
            </Toast>
         </ToastContainer>

            <Modal show={showDeleteInvestmentModal} onHide={handleDeleteModalClose}>
                <Modal.Header>
                    <Modal.Title>Delete investment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete investment with id {itemToBeDeleted}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteModalClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleInvestmentDelete}>
                        Delete investment
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddInvestmentModal} onHide={handleAddModalClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Add new investment</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Investment Id</Form.Label>
                        <Form.Control type="number" readOnly placeholder="1" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Investor name</Form.Label>
                        <Form.Control type="text" value={investorName} onChange={(e) => setInvestorName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                        <Form.Label>Investment amount</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control type="number" onChange={(e) => setInvestmentAmount(e.target.value)}  />
                            <InputGroup.Text id="basic-addon1">€</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                        <Form.Label>Investment start</Form.Label>
                        <Form.Control type="date" onChange={(e) => setInvestmentStart(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label>Investment end</Form.Label>
                        <Form.Control type="date" onChange={(e) => setInvestmentEnd(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                        <Form.Label>Interest</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control type="number" onChange={(e) => setInterest(e.target.value)}  />
                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Investment type</Form.Label>
                        <DropdownButton id="dropdown-basic-button" title={investmentType} variant="dark">
                            <Dropdown.Item onClick={(e) => setInvestmentType("BOND")}>BOND</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => setInvestmentType("LOAN")}>LOAN</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => setInvestmentType("Hoius")}>HOIUS</Dropdown.Item>
                        </DropdownButton>
                    </Form.Group>
                    {addInvestmentValidationFailed == true ? "Error: please check input values!" : ""}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleInvestmentAdd}>
                        Add investment
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row>
                <h1>Modena Investments</h1>
            </Row>
            <Row>
                <Col>
                </Col>
                <Col xs={8}>
                <div align="end"><Button variant="success" onClick={handleAddModalShow}>Add investment</Button></div><br/>
                </Col>
                <Col>
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
                <Col xs={8}>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Investor</th>
                                <th>Amount</th>
                                <th>Investment start</th>
                                <th>Investment end</th>
                                <th>Interest</th>
                                <th>Type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {investorsList.length < 1 ? "Investeeringud puuduvad :(": 

                                    investorsList.map((investor) => (
                                        <tr key={investor.id}>
                                            <td>
                                                {investor.id}
                                            </td>
                                            <td>
                                                {investor.name}
                                            </td>
                                            <td>
                                                {investor.amount} €
                                            </td>
                                            <td>
                                                {dateFormat(investor.investmentStart)}
                                            </td>
                                            <td>
                                                {dateFormat(investor.investmentEnd)}
                                            </td>
                                            <td>
                                                {investor.interest}%
                                            </td>
                                            <td>
                                                {investor.type}
                                            </td>
                                            <td>
                                                <Button variant="danger" onClick={(e) => {handleDeleteModalShow(); setItemToBeDeleted(investor.id)}}>x</Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                        </tbody>
                    </Table>
                </Col>
                <Col>
                </Col>
            </Row>
        </>
    );

}

export default InvestmentsPage;