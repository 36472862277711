import './App.css';
import  InvestmentsPage from './components/pages/InvestmentsPage';
import { useState, useRef, useEffect } from "react";
import { Table, Row, Col, Button, Modal, Form, InputGroup, Dropdown, DropdownButton } from "react-bootstrap";
import auth from './components/api/auth';


function App() {

  const [loggedIn, setLoggedIn] = useState(false);
  const [sessionToken, setSessionToken] = useState(localStorage.getItem("sid") == null ? null : localStorage.getItem("sid"));
  const dataLoaded = useRef(false);
  const [password, setPassword] = useState();
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    if(sessionToken != "false" && sessionToken != null) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
 }, [sessionToken]);

  const handleLogin = ()=> {
    getSessionToken();
  }

  const getSessionToken = async ()=> {  
    const fetchList = async () => {
        const config = {
            headers: {
              Authorization: 'Bearer ' + password}
        };
        const response = await auth.get('', config);
        if(response.data.sessionToken != "false") {
          setSessionToken(response.data.sessionToken);
          setLoggedIn(true);
          setLoginError(false);
          localStorage.setItem("sid", response.data.sessionToken);
        } else {
          setLoggedIn(false);
          setLoginError(true);
        }
    }
    if (dataLoaded.current === false) {
        fetchList();
    }
    return ()=> { dataLoaded.current = true; }
}

  return (
    <div className="App">
      {loggedIn ? <InvestmentsPage sessionToken={sessionToken}/> : 
        <Modal show="show">
        <Modal.Header>
            <Modal.Title>Please log in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
               <Form.Group className="mb-3" controlId="ControlInput1">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="text" onChange={(e) => setPassword(e.target.value)}/>
                </Form.Group>
                {loginError ? "Problem with password" : ""}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" onClick={handleLogin}>
                Log in
            </Button>
        </Modal.Footer>
    </Modal>
      
      }
    </div>
  );
}

export default App;
